

































































































































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";

import Dropdown from "@/app/components/Base/Dropdown.vue";
import Modal from "@/app/components/Base/Modal.vue";
import PanelDropdown from "@/app/components/Base/PanelDropdown.vue";
import { useRoute } from "@/shared/hooks";
import { useProjectTaskStatuses, useTaskStatuses } from "@/shared/hooks/fetchers";
import { Option } from "@/shared/types/components/Base/VSelect";
import { GetTaskQuery } from "@/shared/types/schema.types";

export default defineComponent({
  name: "TaskActionsPanel",
  components: {
    Modal,
    PanelDropdown,
    Dropdown,
  },
  props: {
    isTaskStatusUpdating: {
      type: Boolean,
      default: () => false,
    },
    task: {
      type: Object as PropType<GetTaskQuery["task"]>,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const archiveModalIsShown = ref(false);
    const deleteModalIsShown = ref(false);
    const route = useRoute();
    const projectId = route.params.id;
    const { data: taskStatuses } = projectId? useProjectTaskStatuses({ id: projectId }) : useTaskStatuses();

    const dropdownOptions = computed<Option[]>(() => {
      return taskStatuses.value.map(el => ({
        ...el,
        disabled: !props.task.availableStatuses.find(item => item.id === el.id),
      }));
    });

    const isArchived = computed(() => props.task?.archived);

    const taskStatusIsDisabled = computed(() => props.isTaskStatusUpdating || isArchived.value);

    const unzipIsShown = computed(() => isArchived.value && !props.task?.project?.archived);

    function onArchive() {
      archiveModalIsShown.value = false;
      emit('archive');
    }

    function onDelete() {
      deleteModalIsShown.value = false;
      emit('delete');
    }

    return {
      onArchive,
      isArchived,
      taskStatusIsDisabled,
      onDelete,
      archiveModalIsShown,
      deleteModalIsShown,
      dropdownOptions,
      unzipIsShown,
    };
  },
});
