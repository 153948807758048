











import {  ref } from "@vue/composition-api";
import { defineComponent } from "@vue/composition-api/dist/vue-composition-api";

import { TaskTabValuesEnum } from "@/shared/enums/components/Task/TaskTabs";

export default defineComponent({
  name: "TaskTabs",
  setup() {
    const tabs = [
      {
        id: TaskTabValuesEnum.COMMENTS,
        value: "Комментарии",
      },
      {
        id: TaskTabValuesEnum.CHANGE_LOG,
        value: "История изменений",
      },
    ];
    const activeTab = ref(tabs[0].id);

    return {
      tabs,
      activeTab,
      TaskTabValuesEnum, 
    };
  },
});
