
































import { defineComponent, PropType } from "@vue/composition-api";

import RPriority from "@/app/components/Base/Priority.vue";
import StatusCell from "@/app/components/Base/Status.vue";
import IconArrowRight from "@/app/components/Icons/IconArrowRight.vue";
import { GetTaskQuery } from "@/shared/types/schema.types";
import { formatDateTimeForDisplay, formatFio } from "@/shared/utils";

export default defineComponent({
  name: "TaskToolTip",
  components: {
    RPriority,
    StatusCell,
    IconArrowRight,
  },
  props: {
    task: {
      type: Object as PropType<GetTaskQuery["task"]["subTasks"][0]>,
      default: undefined,
    },
  },
  setup() {
    return {
      formatDateTimeForDisplay,
      formatFio, 
    };
  },
});
