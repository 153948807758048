
















import { defineComponent } from "@vue/composition-api";

import ChangeLog from "@/app/components/ChangeLog/ChangeLog.vue";
import { useRoute } from "@/shared/hooks";
import { useTaskChangeLog } from "@/shared/hooks/fetchers";
import { usePagination } from "@/shared/hooks/pagination";

export default defineComponent({
  name: "TaskChangeLog",
  components: { ChangeLog },
  setup() {
    const route = useRoute();
    const taskId = route.params.taskId;

    const { pagination, changePage } = usePagination();
    const { data: changeLog, loading } = useTaskChangeLog({
      id: taskId,
      pagination: pagination.value,
    });
    return {
      loading,
      changeLog,
      changePage, 
    };
  },
});
