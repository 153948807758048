








































import { useMutation } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import CommentsWrapper from "@/app/components/Comment/CommentsWrapper.vue";
import VComments from "@/app/components/Comment/CommentUnits.vue";
import TaskActionsPanel from "@/app/components/Task/TaskActionsPanel.vue";
import TaskChangeLog from "@/app/components/Task/TaskChangeLog.vue";
import TaskDescription from "@/app/components/Task/TaskDescription.vue";
import TaskTabs from "@/app/components/Task/TaskTabs.vue";
import { EntityTypeEnum } from "@/shared/enums/components/Comment/CommentsWrapper";
import { TaskTabValuesEnum } from "@/shared/enums/components/Task/TaskTabs";
import { useRoute, useRouter } from "@/shared/hooks";
import { useTask } from "@/shared/hooks/fetchers";
import { useNotification } from "@/shared/hooks/notification";
import { EntityInfo } from "@/shared/types/components/Base/commentsWrapper";
import {
  ArchiveTaskDocument,
  RemoveTaskDocument,
  Task,
  UnzipTaskDocument,
  UpdateTaskStatusDocument,
} from "@/shared/types/schema.types";

export default defineComponent({
  components: {
    TaskTabs,
    TaskActionsPanel,
    TaskDescription,
    VComments,
    TaskChangeLog,
    CommentsWrapper,
    BackLink,
  },
  setup() {
    const innerTask = ref<Task>(null as unknown as Task);
    const { errNotification, successNotification } = useNotification();
    const route = useRoute();
    const router = useRouter();
    const taskId = route.params.taskId;
    const projectId = route.params.id;

    const { data: task, loading, onResult: onFetchTask, refetch } = useTask({ id: taskId });

    onFetchTask(({ data: { task } }) => innerTask.value = _.cloneDeep(task) as Task);
    const statusIsUpdating = ref(false);

    const entityInfo: EntityInfo = {
      id: taskId,
      entityType: EntityTypeEnum.TASK,
    };

    const {
      mutate: updateTaskStatusMutation,
      loading: isTaskStatusUpdating,
    } = useMutation(UpdateTaskStatusDocument);

    const { mutate: archiveTaskMutation } = useMutation(ArchiveTaskDocument);
    const { mutate: removeTaskMutation } = useMutation(RemoveTaskDocument);
    const { mutate: unzipTaskMutation } = useMutation(UnzipTaskDocument);

    const isArchived = computed(() => innerTask.value.archived);
    // Обновить статус задачи
    async function updateTaskStatus(statusId: string): Promise<void> {
      try {
        statusIsUpdating.value = true;
        innerTask.value.status.id = statusId;
        const result = await updateTaskStatusMutation({
          id: taskId,
          statusId: innerTask.value.status.id,
        });
        successNotification(`Статус задачи ${innerTask.value.name} изменен на «${result?.data?.task.setStatus.name}»`);
        if (refetch) {
          await refetch({ id: taskId });
        }
      }
      catch(err) {
        innerTask.value = _.cloneDeep(task.value) as Task;
        errNotification(err);
      }
      finally {
        statusIsUpdating.value = false;
      }
    }
    // Отредактировать задачу
    function handleEdit() {
      const editLink = projectId? `/projects/${projectId}/tasks/${taskId}/edit-task` : `/tasks/${taskId}/edit-task`;
      router.push(editLink);
    }

    // Редирект на субтаск
    function handleAddSubtask() {
      const baseLinkPart = projectId? `/projects/${projectId}/new-project-task` : `/new-task`;
      router.push({
        path: baseLinkPart,
        query: { parentTaskId: taskId },
      });
    }
    // Архивировать задачу
    async function handlerArchive() {
      try {
        await archiveTaskMutation({ id: taskId });
        successNotification(`Задача ${innerTask.value.name} перемещена в архив`);
        await router.push(projectId ? `/projects/${projectId}` : '/');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    // Удалить задачу
    async function handlerDelete() {
      try {
        await removeTaskMutation({ id: taskId });
        successNotification(`Задача ${innerTask.value.name} удалена`);
        await router.push(projectId ? `/projects/${projectId}` : '/');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    //Убрать из архива
    async function handleUnzip() {
      try {
        await unzipTaskMutation({ id: taskId });
        successNotification(`Задача ${innerTask.value.name} восстановлена из архива`);
        await router.push(projectId ? `/projects/${projectId}` : '/');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    }

    const backLink = computed(() => projectId? `/projects/${projectId}/` : '/');
    return {
      innerTask,
      loading,
      updateTaskStatus,
      isTaskStatusUpdating,
      handleEdit,
      handleAddSubtask,
      handlerArchive,
      handlerDelete,
      handleUnzip,
      TaskTabValuesEnum,
      statusIsUpdating,
      entityInfo,
      backLink,
      isArchived,
    };
  },
});
