
































































































import { defineComponent, PropType } from "@vue/composition-api";

import AttachmentItem from "@/app/components/Base/AttachmentItem.vue";
import TaskToolTip from "@/app/components/Task/TaskToolTip.vue";
import { AttachmentItemMode } from "@/shared/enums/components/Base/AttachmentItem.enums";
import { GetTaskQuery } from "@/shared/types/schema.types";
import { formatDateTimeForDisplay } from "@/shared/utils";

export default defineComponent({
  name: "TaskDescription",
  components: {
    TaskToolTip,
    AttachmentItem, 
  },
  props: {
    task: {
      type: Object as PropType<GetTaskQuery["task"]>,
      default: null,
    },
  },
  setup() {
    return {
      formatDateTimeForDisplay,
      AttachmentItemMode, 
    };
  },
});
